import {Controller} from "stimulus"

// Connects to data-controller="copy-to-clipboard"
export default class extends Controller {
    static targets = ['source', 'step2']

    connect() {
        /*console.info({controller:'copy-to-clipboard', status:"CONNECTED"});*/
    }
    disconnect() {
        /*console.info({controller:'copy-to-clipboard', status:"DISCONNECTED"});*/
    }

    copy(event) {
        event.preventDefault()
        /*alert('Copiado al Clipboard')*/

        let idStep = '';

        if (event.target.dataset.idstep === undefined) {
            idStep = event.target.parentNode.dataset.idstep;
        } else {
            idStep = event.target.dataset.idstep;
        }

        let paso = $(`#${idStep}`)[0];

        console.log(paso.textContent);
        console.log(paso.value);

        //copiar al clipboard
        if (paso.value === '' || paso.value === undefined) {
            navigator.clipboard.writeText(paso.textContent);
        } else {
            navigator.clipboard.writeText(paso.value);
        }
    }
}
